import * as React from 'react';
import './banner.css';
import DoubleTextGenerator from '../../helpers/double-text';
import { Link } from 'react-router-dom';

export default class Banner extends React.Component {
  render() {
    return (
      <div className="headerBar">
        <Link className="white" to="/100">
          <div className="logo">
            <span>RYAN</span>
            <span>O'CALLAGHAN</span>
          </div>
        </Link>
        {DoubleTextGenerator.CreateText(' LONDON-BASED WEB DEVELOPER', 'white', 'blue')}
      </div>
    );
  }
}
