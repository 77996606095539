import * as React from 'react';
import './home.css';
import { Link } from 'react-router-dom';
import DoubleTextGenerator from '../../helpers/double-text';
import OptionCreator from '../../helpers/options';

export default class Home extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="main-headline">
          <p className="yellow">News</p>
          <Link to="/104">
            <div className="option">
              {DoubleTextGenerator.CreateText('WELCOME TO MY INTERNET-SITE')}
              {DoubleTextGenerator.CreateText('104', 'yellow')}
            </div>
          </Link>
          <hr className="blue"/>
        </div>
        <div className="first-column">
          {this.getOption('WHO AM I', '199')}
          <div className="spacer" />
          {this.getOption('LINKEDIN', '695')}
          <div className="spacer" />
          {this.getOption('GITHUB', '500')}
          <div className="spacer" />
          {this.getOption('INSTAGRAM', '580')}
          <div className="spacer" />
          {this.getOption('E-MAIL', '590')}
          <div className="spacer" />
        </div>
        <div className="second-column">
        {this.getOption('SECRET INGREDIENT', '650')}
        <div className="spacer" />
        {this.getOption('SOUNDCLOUD', '630')}
        <div className="spacer" />
        {this.getOption('MIXCLOUD', '540')}
        <div className="spacer" />
        {this.getOption('LAST.FM', '570')}
          <div className="spacer" />
        </div>
        <div className="page-footer bluebg">
          <p className="yellow">Thank you for coming to my ted talk :)</p>
        </div>
      </div>
    );
  }

  getOption(text: string, pageNum: string) {
    return OptionCreator.GetOption(text, pageNum);
  }
}
