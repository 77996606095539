import * as React from 'react';
import './whoAmI.css';
import { Link } from 'react-router-dom';
import DoubleTextGenerator from '../../helpers/double-text';

export default class Error404 extends React.Component {
  render() {
    return (
        <div className="page">
          <div className="text">{DoubleTextGenerator.CreateText('London-based Web Developer')}</div>
          <div className="text">{DoubleTextGenerator.CreateText('Currently at Mangahigh.com building fun,')}</div>
          <div className="text">{DoubleTextGenerator.CreateText('engaging & interactive web apps to help')}</div>
          <div className="text">{DoubleTextGenerator.CreateText('kids around the world learn maths! wbu?')}</div>
          <p className="link">Go back to page <Link to="/" className="yellow">100</Link></p>
        </div>
    );
  }
}
